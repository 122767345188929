import React from "react";
import { string, bool, func, number } from "prop-types";
import Image from "./image";
import styles from "./image-check-select.module.css";

/**
 * This is a component that looks like an image, but acts like a
 * checkbox.
 *
 * @export
 * @param {*} props
 */
export function ImageCheckSelect({ filename, alt, isChecked, onActivate, id }) {
  return (
    <div
      className={styles.container}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex="0"
      onClick={() => onActivate(id)}
      onKeyPress={e => e.key === " " && onActivate(id)}
      key={id}
    >
      <Image filename={filename} alt={alt} />
      <div className={`${styles.overlay} ${isChecked && styles.selected}`} />
    </div>
  );
}

ImageCheckSelect.propTypes = {
  id: number.isRequired,
  filename: string.isRequired,
  isChecked: bool.isRequired,
  alt: string.isRequired,
  onActivate: func.isRequired
};

import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import QuestionHeader from "../../../components/question-header";
import styles from "./normal-childhood-activities.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { ImageCheckSelect } from "../../../components/image-check-select";

const ACTIVITIES = [
  {
    id: 1,
    filename: "kid-with-legos.jpg",
    alt: "Kid playing with legos"
  },
  {
    id: 2,
    filename: "hiking_cropped.jpg",
    alt: "People hiking"
  },
  {
    id: 3,
    filename: "girl-on-horse.jpg",
    alt: "Small girl on horseback"
  },
  {
    id: 4,
    filename: "family-biking.jpg",
    alt: "Family with child on a bike"
  },
  {
    id: 5,
    filename: "boy-waterskiing.jpg",
    alt: "Boy waterskiing"
  },
  {
    id: 6,
    filename: "bounce-house.jpg",
    alt: "Kids playing in a bounce house"
  }
];

export class NormalChildhoodActivities extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    checkedItems: []
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(4);
  }

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({ hasSubmitted: true });
    } else {
      this.props.goToStep(
        5,
        "/modules/normalcy/reasonable-prudent-parenting-standards"
      );
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({ hasSubmitted: false });
    } else {
      this.props.goToStep(3, "/modules/normalcy/normalcy-law");
    }
  };

  handleActivate = id => {
    if (this.state.checkedItems.includes(id)) {
      this.setState({
        checkedItems: this.state.checkedItems.filter(x => x !== id)
      });
    } else {
      this.setState({
        checkedItems: this.state.checkedItems.concat(id)
      });
    }
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - What is Normalcy"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <span className={styles.headerArea}>
            First, let’s define “normal kid activities.”
          </span>
          <div className={styles.questionHeaderArea}>
            <QuestionHeader
              icon={
                <span
                  className={styles.emoji}
                  role="img"
                  aria-label="Thinking Face"
                >
                  🤔
                </span>
              }
            >
              Which of these are normal childhood activities? Click all that
              apply.
            </QuestionHeader>
          </div>
          <div className={styles.questionArea}>
            {ACTIVITIES.map(a => (
              <div className={styles.imageOption} key={a.id}>
                {this.state.hasSubmitted && (
                  <div
                    className={`${styles.feedback} ${styles.feedbackPositive}`}
                  >
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon
                        icon={faSquare}
                        color="green"
                        size="2x"
                      />
                      <FontAwesomeIcon
                        icon={faCheck}
                        inverse
                        transform="shrink-7"
                        size="2x"
                      />
                      {/* <FontAwesomeIcon icon={faCheckSquare} size="3x" /> */}
                    </span>
                  </div>
                )}
                <ImageCheckSelect
                  id={a.id}
                  filename={a.filename}
                  alt={a.alt}
                  onActivate={this.handleActivate}
                  isChecked={this.state.checkedItems.includes(a.id)}
                />
              </div>
            ))}
          </div>
          {this.state.hasSubmitted && (
            <div className={styles.feedbackBlurb}>
              <p>
                Turns out – they can all be normal activities.{" "}
                <span className={styles.bold}>
                  A ”normal” childhood activity is any activity or experience
                  that allows children and youth to grow, and that is age and
                  developmentally-appropriate.
                </span>
              </p>

              <p>
                There is no complete list of “normal” activities; it depends on
                the kid. Even activities that cost a lot of money or could
                potentially result in injury can be normal kid activities.
              </p>
            </div>
          )}
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(NormalChildhoodActivities);
